import React from 'react';

import { useRefRerender } from '@/core/features/render/use-ref-rerender';
import { atom, useAtom, useAtomValue, useSetAtom } from '@/core/features/store/atom-store';

const navigationInputMethodAtom = atom<'keyboard' | 'mouse'>('mouse');
const useNavigationInputMethod = () => useAtom(navigationInputMethodAtom);

const activeKeyboardItemNameAtom = atom<null | string>(null);
export const useActiveKeyboardItemName = () => useAtomValue(activeKeyboardItemNameAtom);

const activeKeyboardNavigationContainerIdAtom = atom<null | string>(null);
export const useActiveKeyboardNavigationContainerIdAtom = () => useAtomValue(activeKeyboardNavigationContainerIdAtom);

export function useKeyboardNavigationContainerDesktop({
    activeNavigationOnKeyDown,
    containerId,
    customEvents,
    dropDownContainer,
    gridType,
    hasNoKeyboardNavigationItem,
    isActive,
    items,
    itemsChanged,
    keyboardNavigationContainerRef,
    onBlur,
    onCtaClick,
    onFocus,
    skipFirstNavigationItemInRow,
}: {
    activeNavigationOnKeyDown: boolean;
    containerId: string;
    customEvents?: { itemName: string; key: string }[];
    dropDownContainer: React.MutableRefObject<HTMLDivElement | null>;
    gridType: 'Column' | 'Grid' | 'Row';
    hasNoKeyboardNavigationItem?: boolean;
    isActive: boolean;
    items: Element[][];
    itemsChanged: boolean;
    keyboardNavigationContainerRef: React.RefObject<HTMLDivElement>;
    onBlur: () => void;
    onCtaClick?: () => void;
    onFocus: () => void;
    skipFirstNavigationItemInRow: boolean;
}) {
    const highlightedRowIndex = React.useRef(0);
    const highlightedColumnIndex = React.useRef(0);
    const setActiveKeyboardItemName = useSetAtom(activeKeyboardItemNameAtom);
    const setActiveKeyboardNavigationContainerIdAtom = useSetAtom(activeKeyboardNavigationContainerIdAtom);
    const [isFocused, setIsFocused] = React.useState(false);
    const [inputMethod, setInputMethod] = useNavigationInputMethod();
    useRefRerender(dropDownContainer);

    const updateHighlightedIndex = React.useCallback(
        (row?: number, column?: number, shouldContainerGetFocused: boolean = true) => {
            const updatedRow = row !== undefined ? row : highlightedRowIndex.current;
            const updatedColumn = column !== undefined ? column : highlightedColumnIndex.current;

            highlightedRowIndex.current = updatedRow;
            highlightedColumnIndex.current = updatedColumn;

            const highlightedItem = items[updatedRow]?.[updatedColumn];
            if (!(highlightedItem instanceof HTMLElement)) {
                return;
            }

            if (highlightedItem) {
                highlightedItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }

            const name = highlightedItem.getAttribute('data-keyboard-navigation-item');
            if (!isFocused && shouldContainerGetFocused) {
                keyboardNavigationContainerRef.current?.focus();
            }

            if (!name) {
                return;
            }
            setActiveKeyboardItemName(name);
        },
        [isFocused, items, keyboardNavigationContainerRef, setActiveKeyboardItemName],
    );

    const handleMouseHover = React.useCallback(() => {
        setInputMethod('mouse');
        setActiveKeyboardItemName(null);
    }, [setActiveKeyboardItemName, setInputMethod]);

    const onItemSelect = React.useCallback(() => {
        const highlightedItem = items[highlightedRowIndex.current]?.[highlightedColumnIndex.current];
        if (!(highlightedItem instanceof HTMLElement)) {
            return;
        }
        highlightedItem.click();
    }, [items]);

    const setHighlightRowIndexPrevious = React.useCallback(() => {
        const newIndex: number = (() => {
            if (!items) {
                return 0;
            }
            if (highlightedRowIndex.current === 0 || highlightedRowIndex.current > items.length - 1) {
                return items.length - 1;
            }
            return highlightedRowIndex.current - 1;
        })();

        updateHighlightedIndex(newIndex);
    }, [items, updateHighlightedIndex]);

    const setHighlightRowIndexNext = React.useCallback(() => {
        const newIndex: number = (() => {
            if (!items) {
                return 0;
            }
            if (highlightedRowIndex.current >= items.length - 1) {
                return 0;
            }
            return highlightedRowIndex.current + 1;
        })();

        updateHighlightedIndex(newIndex);
    }, [items, updateHighlightedIndex]);

    const setHighlightColumnIndexPrevious = React.useCallback(() => {
        const newIndex: number = (() => {
            const item = items[highlightedRowIndex.current];
            if (!item) {
                return 0;
            }
            if (highlightedColumnIndex.current === 0) {
                return item.length - 1;
            }
            return highlightedColumnIndex.current - 1;
        })();

        updateHighlightedIndex(undefined, newIndex);
    }, [items, updateHighlightedIndex]);

    const setHighlightColumnIndexNext = React.useCallback(() => {
        const newIndex: number = (() => {
            const item = items[highlightedRowIndex.current];
            if (!item) {
                return 0;
            }
            if (highlightedColumnIndex.current >= item.length - 1) {
                return 0;
            }
            return highlightedColumnIndex.current + 1;
        })();

        updateHighlightedIndex(undefined, newIndex);
    }, [items, updateHighlightedIndex]);

    const handleKeyDownActivation = React.useCallback(
        (event: KeyboardEvent) => {
            if (gridType === 'Grid' || gridType === 'Row') {
                if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                    if (inputMethod === 'mouse') {
                        event.preventDefault();
                        setInputMethod('keyboard');
                        updateHighlightedIndex(0, 0);

                        if (skipFirstNavigationItemInRow) {
                            event.key === 'ArrowUp' ? setHighlightRowIndexPrevious() : setHighlightRowIndexNext();
                        }
                    }
                }
            }
            if (gridType === 'Grid' || gridType === 'Column') {
                if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
                    if (inputMethod === 'mouse') {
                        event.preventDefault();
                        setInputMethod('keyboard');
                        updateHighlightedIndex(0, 0);
                    }
                }
            }
            if (customEvents) {
                customEvents.map((customEvent) => {
                    if (event.key === customEvent.key) {
                        if (inputMethod === 'mouse') {
                            event.preventDefault();
                            setInputMethod('keyboard');
                            updateHighlightedIndex(0);
                        }
                    }
                });
            }
        },
        [
            customEvents,
            gridType,
            inputMethod,
            setHighlightRowIndexNext,
            setHighlightRowIndexPrevious,
            setInputMethod,
            skipFirstNavigationItemInRow,
            updateHighlightedIndex,
        ],
    );
    const callItemOnClickByName = React.useCallback(
        (name: string) => {
            if (!items) {
                return;
            }

            const item = items.flat().find((item) => {
                return item.getAttribute('data-keyboard-navigation-item') === name;
            });

            if (!(item instanceof HTMLElement)) {
                return;
            }

            item.click();
        },
        [items],
    );

    const handleKeyDown = React.useCallback(
        (event: KeyboardEvent) => {
            if (!isActive) {
                return;
            }

            if (onCtaClick && event.key === 'Enter') {
                event.preventDefault();
                onCtaClick();
            }

            if (hasNoKeyboardNavigationItem) {
                return;
            }

            if (inputMethod === 'mouse') {
                handleKeyDownActivation(event);
                return;
            }

            if (customEvents) {
                customEvents.map((customEvent) => {
                    if (event.key === customEvent.key) {
                        event.preventDefault();
                        callItemOnClickByName(customEvent.itemName);
                    }
                });
            } else {
                if (gridType === 'Grid' || gridType === 'Row') {
                    if (event.key === 'ArrowUp') {
                        event.preventDefault();
                        setHighlightRowIndexPrevious();
                    } else if (event.key === 'ArrowDown') {
                        event.preventDefault();
                        setHighlightRowIndexNext();
                    }
                }
                if (gridType === 'Grid' || gridType === 'Column') {
                    if (event.key === 'ArrowLeft') {
                        event.preventDefault();
                        setHighlightColumnIndexPrevious();
                    } else if (event.key === 'ArrowRight') {
                        event.preventDefault();
                        setHighlightColumnIndexNext();
                    }
                }
                if (event.key === ' ') {
                    if (!isFocused) {
                        return;
                    }
                    event.preventDefault();
                    onItemSelect();
                }
            }
        },
        [
            callItemOnClickByName,
            customEvents,
            gridType,
            handleKeyDownActivation,
            hasNoKeyboardNavigationItem,
            inputMethod,
            isActive,
            isFocused,
            onCtaClick,
            onItemSelect,
            setHighlightColumnIndexNext,
            setHighlightColumnIndexPrevious,
            setHighlightRowIndexNext,
            setHighlightRowIndexPrevious,
        ],
    );

    React.useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [dropDownContainer, handleKeyDown]);

    React.useEffect(() => {
        if (itemsChanged) {
            updateHighlightedIndex(0, 0, isFocused);
        }
    }, [dropDownContainer, isFocused, items, itemsChanged, updateHighlightedIndex]);

    React.useEffect(() => {
        if (items.length === 0) {
            return;
        }

        if (isActive && inputMethod === 'keyboard') {
            setActiveKeyboardNavigationContainerIdAtom(containerId);
            if (activeNavigationOnKeyDown) {
                updateHighlightedIndex(0, 0, false);
            }
        }
    }, [
        activeNavigationOnKeyDown,
        containerId,
        inputMethod,
        isActive,
        items.length,
        setActiveKeyboardNavigationContainerIdAtom,
        updateHighlightedIndex,
    ]);

    React.useEffect(() => {
        const element = dropDownContainer.current;
        if (!element) {
            return;
        }
        const handleFocus = () => {
            setIsFocused(true);
            setInputMethod('keyboard');
            updateHighlightedIndex(0, 0, isFocused);
            onFocus();
        };
        const handleBlur = () => {
            setIsFocused(false);
            setInputMethod('mouse');
            setActiveKeyboardNavigationContainerIdAtom(null);
            onBlur();
        };

        element.addEventListener('focus', handleFocus);
        element.addEventListener('blur', handleBlur);

        return () => {
            if (!element) {
                return;
            }
            element.removeEventListener('focus', handleFocus);
            element.removeEventListener('blur', handleBlur);
        };
    }, [
        dropDownContainer,
        isFocused,
        onBlur,
        onFocus,
        setActiveKeyboardNavigationContainerIdAtom,
        setInputMethod,
        updateHighlightedIndex,
    ]);

    return {
        inputMethod,
        onMouseHover: handleMouseHover,
    };
}
