import React from 'react';

export default function KeyboardNavigationItemDesktop({
    children,
    customEventKey,
    name,
    onSelect,
}: React.PropsWithChildren<{
    customEventKey?: string;
    name: string;
    onSelect?: React.MouseEventHandler<HTMLDivElement> | React.MouseEventHandler<HTMLElement>;
}>) {
    // NOTE: we are using the onClick of this component to be able to navigate with the keyboard dynamically
    // in the use-keyboard-navigation-container hook. but they should not be causing problems when using the mouse.
    // this way the events get ignored without the need to change anything in the child components
    return (
        <div
            className={'pointer-none'}
            data-keyboard-navigation-item={name}
            data-keyboard-navigation-item-key={customEventKey}
            onClick={(event) => {
                if (event.target !== event.currentTarget) {
                    return;
                }
                onSelect?.(event);
            }}
        >
            <div className={'pointer-auto'}>{children}</div>
        </div>
    );
}
