import React from 'react';

import { useKeyboardNavigationContainerDesktop } from '@/core/components/dropdown/use-keyboard-navigation-container-desktop';

/**
 * KeyboardNavigationContainerPropsBase type definition.
 */
type KeyboardNavigationContainerPropsBase = {
    /** Activates navigation on arrow keys press. @default false */
    activeNavigationOnKeyDown?: boolean;

    /** Activates navigation only when focused. @default false */
    activeOnFocus?: boolean;

    /** Auto-focus if the container is active. @default false */
    autoFocus?: boolean;

    className?: string;
    containerId: string;
    containerStyleWhileActive?: React.CSSProperties;

    /** set to true, if children have custom events (see keyboard-navigation-item.tsx). */
    hasCustomEvents?: boolean;

    /** If true, used only for CTA or overlays without any items. @default false */
    hasNoKeyboardNavigationItem?: boolean;

    /** Indicates if keyboard navigation is active. @default true */
    isActive?: boolean;

    onBlur?: () => void;

    /** Called when the user presses the Enter key. */
    onCtaClick?: () => void;

    onFocus?: () => void;

    /** If true, the navigation will highlight the next keyboard item immeadiately on activation, useful when the first item is highlighted by other conditions (e.g. destination result set, where the first element is highlighted without keyboard navigation) @default false */
    skipFirstNavigationItemInRow?: boolean;
};

type GridProps = { gridColumn: number; gridType?: 'Grid' } | { gridColumn?: never; gridType?: 'Column' | 'Row' };

type KeyboardNavigationContainerProps = KeyboardNavigationContainerPropsBase & GridProps;

type CustomEvent = {
    itemName: string;
    key: string;
};

const NavigationInputClasses = {
    keyboard: 'currentNavigationInput-keyboard',
    mouse: 'currentNavigationInput-mouse',
};

export default function KeyboardNavigationContainerDesktop({
    activeNavigationOnKeyDown = false,
    activeOnFocus = false,
    autoFocus = false,
    children,
    className,
    containerId,
    containerStyleWhileActive,
    gridColumn,
    gridType = 'Row',
    hasCustomEvents,
    hasNoKeyboardNavigationItem = false,
    isActive = true,
    onBlur,
    onCtaClick,
    onFocus,
    skipFirstNavigationItemInRow = false,
}: React.PropsWithChildren<KeyboardNavigationContainerProps>) {
    const keyboardNavigationContainerRef = React.useRef<HTMLDivElement>(null);
    const keyboardNavigationItems = React.useRef<Element[][]>();

    const [customEvents, setCustomEvents] = React.useState<CustomEvent[]>();
    const [isFocused, setIsFocused] = React.useState(false);
    const isMouseDownRef = React.useRef(false);
    const [itemsChanged, setItemsChanged] = React.useState(false);

    const handleBlur = () => {
        setIsFocused(false);
        onBlur?.();
    };

    const handleFocus = () => {
        if (isMouseDownRef.current) {
            return;
        }
        setIsFocused(true);

        keyboardNavigationContainerRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
        });
        onFocus?.();
    };

    const { inputMethod, onMouseHover } = useKeyboardNavigationContainerDesktop({
        activeNavigationOnKeyDown: activeNavigationOnKeyDown,
        containerId,
        customEvents: hasCustomEvents ? customEvents : undefined,
        dropDownContainer: keyboardNavigationContainerRef,
        gridType: gridType,
        hasNoKeyboardNavigationItem: hasNoKeyboardNavigationItem,
        isActive: activeOnFocus ? isFocused && isActive : isActive,
        items: keyboardNavigationItems.current ?? [],
        itemsChanged,
        keyboardNavigationContainerRef: keyboardNavigationContainerRef,
        onBlur: handleBlur,
        onCtaClick: onCtaClick,
        onFocus: handleFocus,
        skipFirstNavigationItemInRow,
    });

    React.useEffect(() => {
        if (!autoFocus) {
            return;
        }
        if (isMouseDownRef.current) {
            isMouseDownRef.current = false;
            return;
        }

        if (isActive && keyboardNavigationContainerRef) {
            keyboardNavigationContainerRef.current?.focus();
        }
    }, [autoFocus, isActive]);

    React.useEffect(() => {
        if (!children || hasNoKeyboardNavigationItem || !keyboardNavigationContainerRef.current || !isActive) {
            return;
        }

        const elements =
            keyboardNavigationContainerRef.current?.querySelectorAll('[data-keyboard-navigation-item]') ?? [];
        const filteredElements = Array.from(elements).filter((element) => {
            const closestContainer = element.closest('[data-keyboard-navigation-container]');
            return closestContainer === keyboardNavigationContainerRef.current;
        });

        if (hasCustomEvents) {
            const extractedCustomEvents: CustomEvent[] = Array.from(filteredElements)
                .map((element) => {
                    const itemName = element.getAttribute('data-keyboard-navigation-item');
                    const key = element.getAttribute('data-keyboard-navigation-item-key');
                    return itemName && key ? { itemName, key } : null;
                })
                .filter((item): item is CustomEvent => item !== null);

            setCustomEvents(extractedCustomEvents);
        }

        const processItems = {
            Column: [[...filteredElements]],
            Grid: gridColumn ? createGrid(filteredElements, gridColumn) : null,
            Row: Array.from(filteredElements).map((item) => [item]),
        }[gridType];
        if (keyboardNavigationItems.current) {
            if (processItems && !equalByDataAttr(processItems, keyboardNavigationItems.current)) {
                setItemsChanged(true);
            } else {
                setItemsChanged(false);
            }
        }

        keyboardNavigationItems.current = processItems ?? [];
    }, [
        children,
        gridColumn,
        gridType,
        hasCustomEvents,
        hasNoKeyboardNavigationItem,
        isActive,
        keyboardNavigationContainerRef,
    ]);

    return (
        <div
            className={`${className ?? ''} ${NavigationInputClasses[inputMethod]}`}
            data-keyboard-navigation-container
            onMouseDown={() => {
                isMouseDownRef.current = true;
            }}
            onMouseOver={onMouseHover}
            ref={keyboardNavigationContainerRef}
            style={isFocused ? containerStyleWhileActive : undefined}
            tabIndex={0}
        >
            {children}
        </div>
    );
}

const equalByDataAttr = (
    keyboardNavigationItemsPrevious: Element[][],
    keyboardNavigationItemsCurrent: Element[][],
): boolean => {
    if (keyboardNavigationItemsPrevious.length !== keyboardNavigationItemsCurrent.length) {
        return false;
    }

    return keyboardNavigationItemsPrevious.every((ItemsPreviousRow, i) => {
        const ItemsCurrentRow = keyboardNavigationItemsCurrent[i];
        if (!ItemsCurrentRow) {
            return;
        }
        if (ItemsPreviousRow.length !== ItemsCurrentRow.length) {
            return false;
        }

        return ItemsPreviousRow.every((itemPrevious, j) => {
            const itemCurrent = ItemsCurrentRow[j];
            if (!itemCurrent) {
                return;
            }
            return (
                itemPrevious.getAttribute('data-keyboard-navigation-item') ===
                itemCurrent.getAttribute('data-keyboard-navigation-item')
            );
        });
    });
};

function createGrid(elements: Element[], gridColumn: number): Element[][] {
    const totalRows = Math.ceil(elements.length / gridColumn);
    const grid: Element[][] = Array.from({ length: totalRows }, (_, rowIndex) => {
        const startIndex = rowIndex * gridColumn;
        const endIndex = startIndex + gridColumn;
        return elements.slice(startIndex, endIndex);
    });
    return grid;
}
